<template>
  <v-dialog
    scrollable
    v-model="dialog"
    max-width="600px"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline"
        >{{ isEditing ? "Edit" : "Add a new" }} Dog</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4">
        <v-form @submit.prevent="saveForm" method="post" id="website-form">
          <v-switch
            label="Display on Boys/Girls Page"
            v-model="fields.front_end_visible"
            inset
            :error="errors.hasOwnProperty('front_end_visible')"
            :error-messages="errors['front_end_visible']"
          ></v-switch>

          <v-switch
            label="Proven"
            v-model="fields.proven"
            inset
            :error="errors.hasOwnProperty('proven')"
            :error-messages="errors['proven']"
          ></v-switch>

          <v-switch
            label="Display on Stud Service Page"
            v-model="fields.display_stud"
            inset
            :error="errors.hasOwnProperty('display_stud')"
            :error-messages="errors['display_stud']"
          ></v-switch>

          <v-switch
            label="Has this dog been placed?"
            v-model="fields.breeding_stock_placed"
            inset
            :error="errors.hasOwnProperty('breeding_stock_placed')"
            :error-messages="errors['breeding_stock_placed']"
          ></v-switch>

          <v-switch
            label="Make available on Breeding Stock Page"
            v-model="fields.available_breeding_stock"
            inset
            :error="errors.hasOwnProperty('available_breeding_stock')"
            :error-messages="errors['available_breeding_stock']"
          ></v-switch>

          <v-textarea
            label="Dog Introduction"
            v-model="fields.dog_introduction"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('dog_introduction')"
            :error-messages="errors['dog_introduction']"
          ></v-textarea>

          <v-textarea
            label="Availability"
            v-model="fields.availability"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('availability')"
            :error-messages="errors['availability']"
          ></v-textarea>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="website-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      fields: {
        front_end_visible: false,
        proven: false,
        available_breeding_stock: false,
        dog_introduction: null,
        availability: null,
        display_stud: false,
        breeding_stock_placed: false,
      },
      dialog: false,
      loading: false,
      isEditing: false,
      errors: {},
    };
  },

  methods: {
    openForm: function (website = null) {
      if (website !== null) {
        this.website = website;
        this.fields.front_end_visible = website.front_end_visible;
        this.fields.proven = website.proven;
        this.fields.available_breeding_stock = website.available_breeding_stock;
        this.fields.dog_introduction = website.dog_introduction;
        this.fields.availability = website.availability;

        this.fields.display_stud = website.display_stud;
        this.fields.breeding_stock_placed = website.breeding_stock_placed;

        this.isEditing = true;
      }

      this.dialog = true;
    },

    saveForm() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        dogId: this.$route.params.dogId,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      payload.fields.formName = "website";

      this.$store
        .dispatch("lhl/dogs/saveBasic", payload)
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
    },
  },
};
</script>
