<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="6">
          <v-card outlined class="mb-6">
            <v-card-title
              class="d-flex justify-start align-center grey lighten-3"
            >
              <div class="flex-grow-1"></div>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.websiteForm.openForm(dog)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit Images Details</span>
              </v-tooltip>
            </v-card-title>
            <v-divider></v-divider>

            <v-simple-table class="mb-5 table" divider="true">
              <tbody>
                <tr>
                  <th>Display on Boys/Girls Page</th>
                  <td>{{ dog.front_end_visible ? "Yes" : "No" }}</td>
                </tr>

                <tr v-if="dog.gender == 'M'">
                  <th>Display on Stud Service Page</th>
                  <td>{{ dog.display_stud ? "Yes" : "No" }}</td>
                </tr>

                <tr>
                  <th>Dog Introduction</th>
                  <td>{{ dog.dog_introduction }}</td>
                </tr>
                <tr>
                  <th>Availability</th>
                  <td>{{ dog.availability }}</td>
                </tr>

                <tr>
                  <th>Proven</th>
                  <td>{{ dog.proven ? "Yes" : "No" }}</td>
                </tr>

                <tr>
                  <th>Make available on Breeding Stock Page</th>
                  <td>{{ dog.available_breeding_stock ? "Yes" : "No" }}</td>
                </tr>

                <tr
                  v-if="
                    dog.available_breeding_stock == 1 &&
                    dog.status == 'breeding-dog'
                  "
                >
                  <th>Has this dog been placed</th>
                  <td>{{ dog.breeding_stock_placed ? "Yes" : "No" }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-col>

        <v-col cols="6">
          <v-card outlined class="mb-6">
            <v-card-title
              class="d-flex justify-start align-center grey lighten-3"
            >
              <div class="flex-grow-1">Images</div>
            </v-card-title>
            <v-divider></v-divider>

            <v-simple-table class="mb-5 table" divider="true">
              <tbody>
                <tr>
                  <th>Front End Images</th>
                  <th>
                    <v-card outlined class="mb-6">
                      <v-card-title
                        class="d-flex justify-start align-center grey lighten-3"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              small
                              depressed
                              color="green lighten-4 green--text"
                              @click="$refs.imagesForm.openForm('front_end')"
                            >
                              <v-icon small>mdi-plus</v-icon>
                            </v-btn>
                          </template>
                          <span>Edit Basic Details</span>
                        </v-tooltip>
                      </v-card-title>
                      <v-divider></v-divider>

                      <v-row>
                        <v-col
                          cols="4"
                          v-for="image in dog.front_end_images"
                          v-bind:key="image.id"
                        >
                          <v-card>
                            <v-img
                              :src="image.asset_urls.url"
                              class="white--text align-end"
                              gradient="to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"
                              width="100"
                            >
                            </v-img>
                            <v-card-actions>
                              <v-btn
                                icon
                                color="red"
                                @click="openDelete(image, 'front_end')"
                              >
                                <v-icon>mdi-delete</v-icon>
                              </v-btn>
                              <v-spacer></v-spacer>
                              <v-btn
                                icon
                                color="blue"
                                @click="
                                  $refs.imagesForm.openForm('front_end', image)
                                "
                              >
                                <v-icon>mdi-pencil</v-icon>
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-card>
                  </th>
                </tr>
                <tr>
                  <th>Other Images</th>
                  <th>
                    <v-card outlined class="mb-6">
                      <v-card-title
                        class="d-flex justify-start align-center grey lighten-3"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              small
                              depressed
                              color="green lighten-4 green--text"
                              @click="$refs.imagesForm.openForm('other')"
                            >
                              <v-icon small>mdi-plus</v-icon>
                            </v-btn>
                          </template>
                          <span>Edit Basic Details</span>
                        </v-tooltip>
                      </v-card-title>
                      <v-divider></v-divider>

                      <v-row>
                        <v-col
                          cols="4"
                          v-for="image in dog.other_images"
                          v-bind:key="image.id"
                        >
                          <v-card>
                            <v-img
                              :src="image.asset_urls.url"
                              class="white--text align-end"
                              gradient="to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"
                              width="100"
                            >
                            </v-img>
                            <v-card-actions>
                              <v-btn
                                icon
                                color="red"
                                @click="openDelete(image, 'other')"
                              >
                                <v-icon>mdi-delete</v-icon>
                              </v-btn>
                              <v-spacer></v-spacer>
                              <v-btn
                                icon
                                color="blue"
                                @click="
                                  $refs.imagesForm.openForm('other', image)
                                "
                              >
                                <v-icon>mdi-pencil</v-icon>
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-card>
                  </th>
                </tr>
                <tr>
                  <th>Images for Hidden Gallery</th>
                  <th>
                    <v-card outlined class="mb-6">
                      <v-card-title
                        class="d-flex justify-start align-center grey lighten-3"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              small
                              depressed
                              color="green lighten-4 green--text"
                              @click="$refs.imagesForm.openForm('hidden')"
                            >
                              <v-icon small>mdi-plus</v-icon>
                            </v-btn>
                          </template>
                          <span>Edit Basic Details</span>
                        </v-tooltip>
                      </v-card-title>
                      <v-divider></v-divider>

                      <v-row>
                        <v-col
                          cols="4"
                          v-for="image in dog.hidden_gallery"
                          v-bind:key="image.id"
                        >
                          <v-card>
                            <v-img
                              :src="image.asset_urls.url"
                              class="white--text align-end"
                              gradient="to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"
                              width="100"
                            >
                            </v-img>
                            <v-card-actions>
                              <v-btn
                                icon
                                color="red"
                                @click="openDelete(image, 'hidden')"
                              >
                                <v-icon>mdi-delete</v-icon>
                              </v-btn>
                              <v-spacer></v-spacer>
                              <v-btn
                                icon
                                color="blue"
                                @click="
                                  $refs.imagesForm.openForm('hidden', image)
                                "
                              >
                                <v-icon>mdi-pencil</v-icon>
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-card>
                  </th>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
      <v-dialog
        scrollable
        v-model="deleteDialog.open"
        persistent
        max-width="290"
      >
        <v-card>
          <v-card-title class="headline">Delete Image</v-card-title>
          <v-card-text>Are you sure you want to delete this image?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" depressed @click="resetDelete"
              >No, cancel</v-btn
            >
            <v-btn
              color="success"
              depressed
              :loading="deleteDialog.loading"
              @click="saveDelete"
            >
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <ImagesForm ref="imagesForm" />
      <WebsiteForm ref="websiteForm" />
    </v-container>
  </div>
</template>

<script>
import ImagesForm from "../components/images/ImagesForm";
import WebsiteForm from "../components/images/WebsiteForm";

export default {
  components: { ImagesForm, WebsiteForm },

  data() {
    return {
      deleteDialog: {
        open: false,
        loading: false,
        image: {},
        type: null,
      },
    };
  },

  computed: {
    dog() {
      return this.$store.getters["lhl/dogs/get"];
    },
  },

  methods: {
    openDelete(image, type) {
      this.deleteDialog.image = image;
      this.deleteDialog.type = type;
      this.deleteDialog.open = true;
    },

    resetDelete() {
      this.deleteDialog.open = false;
      this.deleteDialog.loading = false;
      this.deleteDialog.image = {};
    },

    saveDelete() {
      const appId = this.$route.params.id;
      this.deleteDialog.loading = true;

      this.$store
        .dispatch("lhl/dogs/deleteImage", {
          appId,
          dogId: this.$route.params.dogId,
          imageId: this.deleteDialog.image.id,
          type: this.deleteDialog.type,
        })
        .then(() => this.resetDelete())
        .catch(() => (this.loading = false));
    },
  },
};
</script>
